.up-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 10px; */
  height: 2em;
  /* margin-top: .5em; */
  /* background-color: #e9d8d8; */
  border-radius: 5px;
  border: 1px solid #30363d;
  overflow: hidden;
}

.up-down button {
  height: 16px;
  width: 2.4em;
  background-color: #161b22;
  border: 0.5px solid #30363d;
  color: inherit;
  transition: background-color 0.2s;
}

.up-down button:hover {
  background-color: #1f2a3b;
}
