.add {
    border: 0;
    position: absolute;
    left: -3em;
    top: -.7em;
    background-color: #176e29;
    border-radius: 2px;
    color: inherit;
    padding-top: .1em;
    padding-bottom: .1em;

}

.arrow {
    position: absolute;
    left: -1em;
    top: -.5em;
    width: 0;
    height: 0;
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-left: .7em solid #176e29;
}