.App {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

h1 {
  font-weight: 500;
  font-size: 2em;
}

a {
  color: #aaaaaa;
  transition: color 0.2s;
}

a:hover {
  color: #5682c4;
}

.svg-button {
  border: 2px solid transparent;
  transition: border 0.2s;
}

.svg-button:hover {
  border: 2px solid #5d6c82;
}

hr {
  margin: 20px;
  color: #30363d55;
  /* background-color: #30363d; */
  /* border-color: #30363d; */
}

.footer {
  margin-top: 3em;
  margin-bottom: 1em;
  font-size: 0.8em;
}
