.remove {
    border: 0;
    position: absolute;
    left: -3em;
    top: calc(50% - 1em);
    background-color: #9b1717;
    color: inherit;
    border-radius: 2px
}

.remove:hover {
    background-color: #922f2f;
}