.options {
  border: 1px solid #30363d;
  border-radius: 6px;
  margin-top: 2em;
  padding: 1em 1em 2em 2em;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.options-row {
  /* border: 1px solid #30363d22; */
  margin: 0.2em;
  display: flex;
}

.options-column {
  /* border: 1px solid #30363d22; */
  width: 50%;
  /* padding-left: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.options select {
  border: 2px solid #30363d;
  background-color: #161b22;
  border-radius: 6px;
  padding: 0.5em;
  width: 17em;
  transition: background-color 0.2s;
}

.options .input {
  position: relative;
  /* border: 2px solid #30363d; */
  /* background-color: #161b22; */
  padding: 0.5em;
  border-radius: 6px;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
}

.options .input input {
  background-color: #00000000;
  /* border: 1px solid #30363d22; */
  font-size: 1em;
  color: #ffffff;
  width: 5em;
  /* margin-right: 1em; */
  text-align: right;
  /* appearance: none;
    margin: 0; */
}

.megabyte {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.7em;
  margin-left: 0.2em;
  margin-right: 0.5em;
  color: #aaaaaa;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.options .input label {
  border: 1px solid #30363d22;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* width: 5em; */
}

.checkbox {
  margin: 1em;
}

input[type="file"] {
  display: none;
}

.file-upload,
.options .button {
  color: inherit;
  font-size: inherit;
  border: 2px solid #30363d;
  background-color: #161b22;
  border-radius: 6px;

  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-upload:hover,
.options:hover .button:hover,
.options select:hover {
  background-color: #1f2a3b;
}
